import { useEffect } from "react";
import { useContext, useState, useRef } from "react";
import { langContext } from "../../context/langContext";

//Importacion de estilos
import "./pModal.css";

const PModal = ({ dataModal }) => {
  const zoomRef = useRef();
  const { locale } = useContext(langContext);
  const { category, model, rutas, usLang, esLang } = dataModal;
  const [imageMain, setImageMain] = useState(0);
  const lang = locale === "en-US" ? usLang : esLang;

  const handleMainImage = (index) => {
    setImageMain(index);
  };

  const handleZoom = () => {
    const divZoom = zoomRef.current;
    let device = window.screen.width < 800 ? "touchmove" : "mousemove";
    divZoom.addEventListener(device, (e) => {
      let imageZoom = e.currentTarget;
      let offsetX = 0;
      let offsetY = 0;
      let x = 0;
      let y = 0;
      e.offsetX ? (offsetX = e.offsetX) : (offsetX = e.touches[0].pageX);
      e.offsetY ? (offsetY = e.offsetY) : (offsetY = e.touches[0].pageY);
      x = (offsetX / imageZoom.offsetWidth) * 100;
      y = (offsetY / imageZoom.offsetHeight) * 100;
      imageZoom.style.backgroundPosition = x + "% " + y + "%";
    });
  };

  useEffect(() => {
    handleZoom();
    // return () => {
    //   handleZoom();
    // };
  }, []);

  useEffect(() => {
    setImageMain(0);
  }, [rutas]);

  return (
    <div className="pcts__modal">
      <div className="pts-modal__images">
        <div
          ref={zoomRef}
          className="p-m-images__zoom"
          style={{ backgroundImage: `url(${rutas[imageMain]})` }}
        >
          <img
            className="p-m-images__mainImage"
            src={rutas[imageMain]}
            alt="drumco product"
            loading="lazy"
          />
        </div>
        <div className="p-m-images__thums">
          {rutas.map((img, index) => (
            <img
              key={index}
              className="p-m-images__thum"
              src={img}
              alt="drumco product"
              loading="lazy"
              onClick={() => handleMainImage(index)}
            />
          ))}
        </div>
      </div>
      <div className="pts-modal__content">
        <h3 className="p-m-content__title">{category + " " + model}</h3>
        <p className="p-m-content__description">{lang.description}</p>
        <ul className="p-m-content__list">
          {lang.features.map((feat, index) => (
            <li key={index} className="p-m-c__list-item">
              {feat}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PModal;
