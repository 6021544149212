import React from "react";
import { FormattedMessage } from "react-intl";
import IMG1 from "../../assets/img/LOGO-AMAZON.png";
import IMG2 from "../../assets/img/LOGO-MUSICCLUB.png";
import IMG3 from "../../assets/img/LOGO-HMC.png";
import IMG4 from "../../assets/img/LOGO-TOPMUSIC.png";

//Importacion de estilos
import "./distributors.css";

const Distributors = () => {
  return (
    <div id="dis" className="distributors">
      <div className="sesgoArriba"></div>
      <div className="dis__title">
        {/* <span>
          <FormattedMessage id="distributors.title1" defaultMessage="Who" />
        </span> */}
        <h2>
          <FormattedMessage
            id="distributors.title2"
            defaultMessage="Distributors"
          />
        </h2>
      </div>
      <div className="dis__list">
        <article className="dis__card">
          <a href="https://www.amazon.com.mx/" target="blank">
            <img className="image__logos" src={IMG1} alt="logotipo amazon" />
          </a>
        </article>
        <article className="dis__card">
          <a href="http://www.musicclub.com.mx/" target="blank">
            <img
              className="image__logos"
              src={IMG2}
              alt="logotipo music club"
            />
          </a>
        </article>
        <article className="dis__card">
          <a href="https://hermesmusicclub.mx/" target="blank">
            <img
              className="image__logos"
              src={IMG3}
              alt="lodgo tipo hermes music"
            />
          </a>
        </article>
        <article className="dis__card">
          <a href="https://topmusic.com.mx/" target="blank">
            <img className="image__logos" src={IMG4} alt="logotipo top music" />
          </a>
        </article>
      </div>
    </div>
  );
};

export default Distributors;
