import { useState, useContext } from "react";
import { useModal } from "../hooks/useModal";
import Modal from "../components/Modal/Modal";
import { dataContext } from "../context/dataContext";
import PModal from "../components/productModal/PModal";
import Products from "../components/products/Products";

const initialModal = {
  id: "",
  model: "",
  category: "",
  usLang: {
    smallDescription: "",
    description: "",
    features: [],
  },
  esLang: {
    smallDescription: "",
    description: "",
    features: [],
  },
  urlImg: "",
  rutas: [],
};

const ProductsPage = () => {
  const { dataProducts } = useContext(dataContext);
  const { isOpen, openModal, closeModal } = useModal(false);
  const [modalP, setModalP] = useState(initialModal);

  return (
    <div>
      <Products
        openModal={openModal}
        data={dataProducts}
        setModalP={setModalP}
      />
      <Modal isOpen={isOpen} closeModal={closeModal}>
        <PModal dataModal={modalP} />
      </Modal>
    </div>
  );
};

export default ProductsPage;
