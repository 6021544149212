import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import HERO from "../../assets/img/heroClean.png";
import PATCHES from "../../assets/img/patches.png";
import LOGO from "../../assets/img/Logo.png";
import "./hero.css";

export const Hero = () => {
  return (
    <div id="hero" className="hero">
      <img
        className="hero__img"
        src={HERO}
        alt="life style drums"
        loading="lazy"
      />
      <div className="hero__content">
        <div className="hero__info">
          <div className="hero__text">
            <h2>
              <FormattedMessage
                id="hero.patchText"
                defaultMessage="Experimenta y asombrate con nuestra nueva serie de parches"
              />
            </h2>
            <img
              src={LOGO}
              alt="logo drumco"
              className="hero__logo"
              loading="lazy"
            />
            <img
              src={PATCHES}
              alt="parches drumco"
              className="text__patches"
              loading="lazy"
            />
            <button className="hero__btn">
              <Link className="hero__btn-link" to="/products/Drumhead">
                <FormattedMessage
                  id="hero.btn-products"
                  defaultMessage="See products"
                />
              </Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
