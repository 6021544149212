import { langContext } from "../../context/langContext";
import { FormattedMessage } from "react-intl";
import { useContext } from "react";
import { demos } from "./demos";

//Importacion de estilos
import "./demos.css";

const renderDemo = (demo, locale) => {
  const { demoId, title, gama, description, esDescription, urlMedia } = demo;
  const currentDes = locale === "en-US" ? description : esDescription;
  return (
    <article key={demoId} className="demos__card">
      <h3 className="de-card__title">
        <FormattedMessage
          id={`demos.card${demoId}-title`}
          values={{ title: title }}
        />
      </h3>
      <span className="de-card__date">
        <FormattedMessage
          id={`demos.card${demoId}-gama`}
          values={{ gama: gama }}
        />
      </span>
      <p className="de-card__description">
        <FormattedMessage
          id={`demos.card${demoId}-description`}
          values={{ description: currentDes }}
        />
      </p>
      <iframe
        width="300"
        height="210"
        src={urlMedia}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      ></iframe>
    </article>
  );
};

const Demos = () => {
  const { locale } = useContext(langContext);

  return (
    <div
      id="demos"
      className="demos"
      style={{ backgroundImage: `url("https://i.ibb.co/bWTmRhm/demos.jpg")` }}
    >
      <div className="demos__shadow">
        <div className="demos__content">
          <h2 className="de-content__title">
            <FormattedMessage id="demos.title" defaultMessage="Demos" />
          </h2>
          <div className="de-content__list">
            {demos.map((demo) => renderDemo(demo, locale))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Demos;
