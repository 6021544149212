export const images = [
  {
    title: "Guadalajara",
    esTitle: "Guadalajara",
    description:
      "Guadalajara joins the family and becomes part of the new world of DrumCo drums.",
    esDescription:
      "Guadalajara se une a la familia y se vualve parte del nuevo mundo de las baterias DrumCo",
    urlImage: "https://i.ibb.co/xjQ8x3K/guadalajara.jpg",
    alt: "texto alternativo",
  },
  {
    title: "Torreon",
    esTitle: "Torreon",
    description:
      "Torreon joins the family and becomes part of the new world of DrumCo drums.",
    esDescription:
      "Torreon se une a la familia y se vualve parte del nuevo mundo de las baterias DrumCo",
    urlImage: "https://i.ibb.co/8zgZWVQ/torreon.jpg",
    alt: "texto alternativo",
  },
  {
    title: "Culiacan",
    esTitle: "Culiacan",
    description:
      "Culiacan joins the family and becomes part of the new world of DrumCo drums.",
    esDescription:
      "Culiacan se une a la familia y se vualve parte del nuevo mundo de las baterias DrumCo",
    urlImage: "https://i.ibb.co/XC1NYCx/culicacan.jpg",
    alt: "texto alternativo",
  },
  {
    title: "Merida",
    esTitle: "Merida",
    description:
      "Merida joins the family and becomes part of the new world of DrumCo drums.",
    esDescription:
      "Merida se une a la familia y se vualve parte del nuevo mundo de las baterias DrumCo",
    urlImage: "https://i.ibb.co/BPj3QT6/merida.jpg",
    alt: "texto alternativo",
  },
];
