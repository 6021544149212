export const images = [
  {
    urlImage: "https://i.ibb.co/2g8pwMC/gale2.jpg",
    alt: "bateria drumco junior azul",
    className: "tall",
    classNameImg: "vertical",
    info: {
      es: {
        title: "Bateria Acustica",
        text: "Batería a un nivel que exige el sonido y aspecto profesional de las baterías",
      },
      us: {
        title: "Acoustic Drum",
        text: "Drums at a level that demands the professional sound and look of drums",
      },
    },
  },
  {
    urlImage: "https://i.ibb.co/xgFzbgy/gale11.jpg",
    alt: "bateria electrica touring",
    className: "",
    classNameImg: "horizontal",
  },
  {
    urlImage: "https://i.ibb.co/9gktYwT/gale3.jpg",
    alt: "platillos bateria drumco",
    className: "tall",
    classNameImg: "vertical",
    info: {
      es: {
        title: "Bateria Junior",
        text: "Ese primer paso esencial en el camino hacia la grandeza de la percusión.",
      },
      us: {
        title: "Junior Drum",
        text: "That essential first step on the path to percussion greatness.",
      },
    },
  },
  {
    urlImage: "https://i.ibb.co/PYbRMpZ/gale1.jpg",
    alt: "bateria drumco pro",
    className: "",
    classNameImg: "horizontal",
  },
  {
    urlImage: "https://i.ibb.co/bBQVYtc/gale4.jpg",
    alt: "bateria junior azul",
    className: "",
    classNameImg: "horizontal",
  },
  {
    urlImage: "https://i.ibb.co/71jf49S/gale7.jpg",
    alt: "bateria drumco master azul",
    className: "",
    classNameImg: "horizontal",
  },
  {
    urlImage: "https://i.ibb.co/j6G6qmB/gale8.jpg",
    alt: "bateria drumco master roja",
    className: "",
    classNameImg: "horizontal",
  },
  {
    urlImage: "https://i.ibb.co/tKMMbJn/gale12.jpg",
    alt: "bateria electronica drumco master azul",
    className: "tall",
    classNameImg: "vertical",
    info: {
      es: {
        title: "Bateria Electrica",
        text: "Disfruta tocando y expandiendo tus horizontes sónicos y estilísticos.",
      },
      us: {
        title: "Electric Drum",
        text: "Enjoy playing and expanding your sonic and stylistic horizons.",
      },
    },
  },
  {
    urlImage: "https://i.ibb.co/z8sqNWw/gale9.jpg",
    alt: "bateria electrica drumco master roja",
    className: "",
    classNameImg: "horizontal",
  },
  {
    urlImage: "https://i.ibb.co/N9FD2Dn/gale6.jpg",
    alt: "timbal drumco",
    className: "tall",
    classNameImg: "vertical",
    info: {
      es: {
        title: "Timbales",
        text: "Materiales de alta calidad y acabados que superaran tus espectativas.",
      },
      us: {
        title: "Timpani",
        text: "High quality materials and finishes that will exceed your expectations.",
      },
    },
  },
  {
    urlImage: "https://i.ibb.co/hD0QPxy/gale10.jpg",
    alt: "bateria electrica drumco touring amarilla",
    className: "",
    classNameImg: "horizontal",
  },
  {
    urlImage: "https://i.ibb.co/tDRkdRJ/gale5.jpg",
    alt: "bateria electrica drumco master roja",
    className: "",
    classNameImg: "vertical",
  },
];
