export const demos = [
  {
    demoId: "1",
    title: "COPPER DRUM",
    gama: "Touring",
    description:
      "The COPPER Drumco is the ideal electronic instrument to practice and improve your skills.",
    esDescription:
      "La COPPER Drumco es el instrumento electrónico ideal para practicar y mejorar tus habilidades.",
    urlMedia: "https://www.youtube.com/embed/ugnFti3T68Y",
  },
  {
    demoId: "2",
    title: "MERCURY DRUM",
    gama: "Master",
    description:
      "The MERCURY Drumco is the ideal electronic instrument for practicing and rehearsing your presentations.",
    esDescription:
      "La MERCURY Drumco es el instrumento electrónico ideal para practicar y ensayar tus presentaciones.",
    urlMedia: "https://www.youtube.com/embed/ld9QLJr0nd8",
  },
  {
    demoId: "3",
    title: "TITANIUM DRUM",
    gama: "Professional",
    description:
      "The TITANIUM Drumco is the ideal electronic instrument to take anywhere and to any performance.",
    esDescription:
      "La TITANIUM Drumco es el instrumento electrónico ideal para llevar a cualquier lugar y a cualquier presentación.",
    urlMedia: "https://www.youtube.com/embed/8Bmd49qgCOo",
  },
];
