import { createContext, useState } from "react";
import { useSearchParams } from "react-router-dom";

const searchContext = createContext();

const SearchProvider = ({ children }) => {
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const datos = {
    search,
    setSearch,
    searchParams,
    setSearchParams,
  };

  return (
    <searchContext.Provider value={datos}>{children}</searchContext.Provider>
  );
};

export { SearchProvider, searchContext };
