import React from "react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { langContext } from "../../context/langContext";
import { searchContext } from "../../context/searchContext";

//Importacion de estilos
import "./products.css";

const renderProduct = (product, index, handleModalImage, locale) => {
  const { model, usLang, esLang, urlImg, category } = product;
  const lang = locale === "en-US" ? usLang : esLang;
  return (
    <article
      key={index}
      className="products__card"
      onClick={() => handleModalImage(product)}
    >
      <img
        className="ps-card__image"
        src={urlImg}
        alt="imagen de producto"
        loading="lazy"
      />
      <div className="ps-card__line"></div>
      <h3 className="ps-card__title">{model}</h3>
      <div className="ps-card__circle"></div>
      <span className="ps-card__model">{category}</span>
      <p className="ps-card__description">{lang.smallDescription}</p>
    </article>
  );
};

const renderProductsList = (data, handleModalProduct, locale) => {
  return (
    <div className="products__list-c">
      {data?.length > 0 ? (
        data?.map((el, index) =>
          renderProduct(el, index, handleModalProduct, locale)
        )
      ) : (
        <h1 className="noresults__msg">No results</h1>
      )}
    </div>
  );
};

const Products = ({ openModal, data, setModalP }) => {
  const { locale } = useContext(langContext);
  const { searchParams } = useContext(searchContext);
  const { category } = useParams();
  let inParam = searchParams.get("search") || " ";
  let strCat = category ? category.replace("-", " ") : "Products";

  const searchFilter = () => {
    // eslint-disable-next-line
    let matchSearch = data?.filter((el) => {
      if (
        el.model.toLowerCase().includes(inParam.toLowerCase()) ||
        el.category.toLowerCase().includes(inParam.toLowerCase()) ||
        el.usLang.description.toLowerCase().includes(inParam.toLowerCase()) ||
        el.usLang.smallDescription
          .toLowerCase()
          .includes(inParam.toLowerCase()) ||
        el.esLang.description.toLowerCase().includes(inParam.toLowerCase()) ||
        el.esLang.smallDescription.toLowerCase().includes(inParam.toLowerCase())
      ) {
        return el;
      }
    });
    return matchSearch;
  };

  const handleModalProduct = (dataModal) => {
    setModalP(dataModal);
    openModal();
  };

  let dataFiltered;

  if (strCat !== "Products") {
    dataFiltered = data.filter((dato) => dato.category === strCat);
  } else if (inParam !== " ") {
    let searchResult = searchFilter(data);
    dataFiltered = searchResult;
  } else {
    dataFiltered = data;
  }

  return (
    <section className="products">
      <h2 className="products__title">{strCat}</h2>
      {renderProductsList(dataFiltered, handleModalProduct, locale)}
    </section>
  );
};

export default Products;
