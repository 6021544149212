import { langContext } from "../../context/langContext";
import { MdOutlineTouchApp } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { images } from "./images";

//Importacion de estilos
import "./gallery.css";
import { useContext } from "react";

const renderInfo = (info, locale) => {
  let langInfo = locale === "en-US" ? info.us : info.es;
  return (
    <div className="i-front__cont">
      <h3 className="i-front__title">{langInfo.title}</h3>
      <p className="i-front__text">{langInfo.text}</p>
    </div>
  );
};

const renderImage = (index, image, handleModalImage, locale) => {
  const { urlImage, className, info } = image;
  return (
    <div
      className={`g-grid__item ${className}`}
      style={{ backgroundImage: `url(${urlImage})` }}
      onClick={() => handleModalImage(image)}
      key={index}
    >
      <div className="item__front">
        {className === "tall" ? renderInfo(info, locale) : ""}
        <MdOutlineTouchApp className="i-front__touch" />
      </div>
    </div>
  );
};

const Gallery = ({ openModal, setModalG }) => {
  const { locale } = useContext(langContext);

  const handleModalImage = (data) => {
    const { urlImage, alt, classNameImg } = data;
    let dataModal = {
      urlImage,
      alt,
      classNameImg,
    };
    setModalG(dataModal);
    openModal();
  };

  return (
    <section id="gallery" className="gallery">
      <div className="gallery__info">
        <h2 className="g-info__title">
          <FormattedMessage id="gallery.title" defaultMessage="Gallery" />
        </h2>
        <p className="g-info__text">
          <FormattedMessage id="gallery.text" />
        </p>
      </div>
      <div className="gallery__grid">
        {images.map((img, index) =>
          renderImage(index, img, handleModalImage, locale)
        )}
      </div>
    </section>
  );
};

export default Gallery;
