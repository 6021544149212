import React from "react";
import LOGO from "../../assets/img/Logo.png";
import { Link } from "react-router-dom";
import TERMS from "../../assets/files/Terminos de uso.pdf";
import POLICY from "../../assets/files/Politica de privacidad.pdf";
import { FormattedMessage } from "react-intl";

//Importacion de estilos
import "./footer.css";

const Footer = () => {
  return (
    <div className="footeR">
      <div className="footer__content">
        <div className="footer__c-imge">
          <img className="footer__img" src={LOGO} alt="logotipo Drumco" />
          <div className="back__fig"></div>
        </div>
        <div className="footer__links">
          <h3 className="links__title">
            <FormattedMessage
              id="footer.topProducts"
              defaultMessage=" Top products"
            />
          </h3>
          <ul>
            <li className="link__item">
              <Link to="/products?search=titanium">Titanium</Link>
            </li>
            <li className="link__item">
              <Link to="/products?search=mercury">Mercury</Link>
            </li>
            <li className="link__item">
              <Link to="/products?search=copper">Copper</Link>
            </li>
            <li className="link__item">
              <Link to="/products?search=obelix">Obelix</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="copy">
        <p>© Copyright 2022, Drumco, S.A. de C.V.</p>
        <div>
          <span className="footer__pol">
            <a href={TERMS} download="">
              <FormattedMessage
                id="footer.terms"
                defaultMessage=" Terms of service"
              />
            </a>
          </span>
          <span className="footer__pol2">
            <a href={POLICY} download="">
              <FormattedMessage
                id="footer.policy"
                defaultMessage="Privacy Policy"
              />
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
