export const sendEmailService = (emailInfo) => {
  const url = "https://back-dc-production.up.railway.app/api/v1/email/send-me";
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(emailInfo),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};
