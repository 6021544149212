import React from "react";

const ProductPage = () => {
  return (
    <div>
      <p>Hola</p>
    </div>
  );
};

export default ProductPage;
