import React, { useState } from "react";
import IMG from "../../assets/img/contact.svg";
import { BsFillPhoneFill } from "react-icons/bs";
import { FaMailBulk, FaUsersCog } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import TERMS from "../../assets/files/Terminos de uso.pdf";
import POLICY from "../../assets/files/Politica de privacidad.pdf";
import LOADER from "../../assets/img/loader.svg";
import { sendEmailService } from "../../services/email";

//Importacion estilos
import "./contact.css";

const initialForm = {
  name: "",
  email: "",
  phone: "",
  message: "",
  termsAgree: false,
};

const Contact = () => {
  const [contactForm, setContactForm] = useState(initialForm);
  const [loader, setloader] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);

  const handleChange = (e) => {
    setContactForm({
      ...contactForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setloader(true);

    const params = {
      ...contactForm,
      termsAgree: Boolean(contactForm.termsAgree),
    };

    sendEmailService(params)
      .then((data) => {
        setloader(false);
        setSendMessage(true);
        e.target.reset();
        setTimeout(() => {
          setSendMessage(false);
        }, 3000);
        return data;
      })
      .catch((error) => console.error("Error:", error))
      .then((response) => console.log("Success:", response));
  };

  return (
    <div id="contact" className="contact">
      <div className="contact__content">
        <form className="c-content__form" action="" onSubmit={handleSubmit}>
          <h2 className="contact__title">
            <FormattedMessage id="contact.title" defaultMessage="Contact us" />
          </h2>
          <input
            name="name"
            type="text"
            placeholder="Name"
            required
            onChange={handleChange}
          />

          <input
            name="email"
            type="email"
            placeholder="Email"
            required
            onChange={handleChange}
          />

          <input
            name="phone"
            type="tel"
            placeholder="Phone"
            required
            onChange={handleChange}
          />

          <textarea
            name="message"
            id=""
            cols="30"
            rows="7"
            placeholder="Message"
            required
            onChange={handleChange}
          ></textarea>
          <div className="form__c-check">
            <input
              type="checkbox"
              name="termsAgree"
              id="terms"
              value={true}
              required
              onChange={handleChange}
            />
            <label htmlFor="terms">
              <FormattedMessage
                id="contact.terms1"
                defaultMessage="I agree with the"
              />
              <a className="terms__links" href={TERMS} download="">
                <FormattedMessage
                  id="contact.terms2"
                  defaultMessage=" Terms of use"
                />
              </a>
              <FormattedMessage id="contact.terms3" defaultMessage=" and" />
              <a className="terms__links" href={POLICY} download="">
                <FormattedMessage
                  id="contact.terms4"
                  defaultMessage=" Privacy Pilicy"
                />
              </a>
              <FormattedMessage
                id="contact.terms5"
                defaultMessage=" and I declare that I have read the information that is required"
              />
            </label>
          </div>
          <button type="submit">
            <FormattedMessage id="contact.sendBtn" defaultMessage="Send" />
          </button>
          <div className="form__messageSend">
            <img
              className={`form__loader ${loader && "show"}`}
              src={LOADER}
              alt="loader"
            />
            <p className={`form__sendSucces ${sendMessage && "show"}`}>
              <FormattedMessage
                id="contact.form.dataSend"
                defaultMessage="The data has been send"
              />
            </p>
          </div>
        </form>

        <div className="contact__btns">
          <div className="btns__c-img">
            <img className="btns__image" src={IMG} alt="" />
          </div>
          <div className="btns__c-btns">
            <a href="tel:+525550022200" className="btns__btn">
              <div className="b-btn__icon">
                <BsFillPhoneFill />
              </div>
              <div className="b-btn__text">
                <span className="text__title">
                  <FormattedMessage id="contact.phone" defaultMessage="Phone" />
                </span>
                <span className="text__info">+52 5550022200</span>
              </div>
            </a>
            <a href="tel:+525550022200" className="btns__btn">
              <div className="b-btn__icon">
                <FaUsersCog />
              </div>
              <div className="b-btn__text">
                <span className="text__title">
                  <FormattedMessage
                    id="contact.specialist"
                    defaultMessage="Specialist"
                  />
                </span>
                <span className="text__info">+52 5550022200</span>
              </div>
            </a>
            <a href="mailto:info@drumco.mx" className="btns__btn">
              <div className="b-btn__icon">
                <FaMailBulk />
              </div>
              <div className="b-btn__text">
                <span className="text__title">
                  <FormattedMessage id="contact.email" defaultMessage="Email" />
                </span>
                <span className="text__info">info@drumcodrums.com</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
