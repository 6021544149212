import IMGCAL from "../../assets/img/calendar.svg";
import { FormattedMessage } from "react-intl";
import { images } from "./images";
import { useContext } from "react";
import { langContext } from "../../context/langContext";

//importacion de estilos
import "./events.css";

const renderSesgoUp = () => {
  return (
    <div className="rect1" style={{ height: "150px", overflow: "hidden" }}>
      <svg
        viewBox="0 0 500 150"
        preserveAspectRatio="none"
        style={{ height: "100%", width: "100%" }}
      >
        <path
          d="M0.00,49.98 C149.99,150.00 271.49,-49.98 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"
          style={{ stroke: "none", fill: "#f4f4f9" }}
        ></path>
      </svg>
    </div>
  );
};

const renderSesgoDown = () => {
  return (
    <div className="rect" style={{ height: "150px", overflow: "hidden" }}>
      <svg
        viewBox="0 0 500 150"
        preserveAspectRatio="none"
        style={{ height: "100%", width: "100%" }}
      >
        <path
          d="M0.00,49.98 C149.99,150.00 349.20,-49.98 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
          style={{ stroke: "none", fill: "#f4f4f9" }}
        ></path>
      </svg>
    </div>
  );
};

const renderCardEvent = (data, index, locale) => {
  const { title, esTitle, description, esDescription, urlImage, alt } = data;
  const langTit = locale === "en-US" ? title : esTitle;
  const langDes = locale === "en-US" ? description : esDescription;
  return (
    <div className="events__card" key={index}>
      <div className="e-card__contenido">
        <h3 className="e-card__title">
          <FormattedMessage
            id={`events.card${index + 1}-title`}
            values={{ title: langTit }}
            defaultMessage="Event Drums"
          />
        </h3>
        <div className="e-card__line"></div>
        <p className="e-card__text">
          <FormattedMessage
            id={`events.card${index + 1}-description`}
            values={{ description: langDes }}
          />
        </p>
      </div>
      <div className="e-card__cont-img">
        <div className="e-card__cont-imgfront"></div>
        <img className="e-card__imge" src={urlImage} alt={alt} />
      </div>
    </div>
  );
};

const Events = () => {
  const { locale } = useContext(langContext);

  return (
    <section id="events" className="events">
      {renderSesgoUp()}

      <div className="events__container">
        <div className="events__introduction">
          <div className="intro__cont-img">
            <img className="intro__image" src={IMGCAL} alt="" />
          </div>
          <h2 className="introduction__title">
            <FormattedMessage id="events.title" defaultMessage="Events" />
          </h2>
          <p className="introduction__text">
            <FormattedMessage id="events.text" />
          </p>
        </div>
        <div className="events__feat">
          {images.map((img, i) => renderCardEvent(img, i, locale))}
        </div>
      </div>

      {renderSesgoDown()}
    </section>
  );
};

export default Events;
