import React from "react";
import { FormattedMessage } from "react-intl";
import Product from "../product/Product";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { dataContext } from "../../context/dataContext";

import "./featProducts.css";
import { useContext } from "react";

export const FeatProducts = () => {
  const { dataProducts } = useContext(dataContext);
  const settings = {
    className: "center change-width",
    centerMode: true,
    infinite: true,
    centerPadding: "115px",
    slidesToShow: 3,
    speed: 500,
    dots: true,
    autoplay: true,
    autoplaySpeed: 2000,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          centerPadding: "12px",
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          centerPadding: "220px",
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          centerPadding: "148px",
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          centerPadding: "84px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerPadding: "1px",
        },
      },
    ],
  };

  return (
    <div id="topP" className="featProducts">
      <h2 className="featProducts__title">
        <FormattedMessage id="products.title" defaultMessage="Top Products" />
      </h2>
      <div className="prueba">
        <Slider {...settings}>
          {dataProducts
            .filter((product) => product.fav === true)
            .map((dato) => (
              <div key={dato.id}>
                <Product data={dato} />
              </div>
            ))}
        </Slider>
      </div>
    </div>
  );
};
