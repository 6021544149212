import React, { useContext } from "react";
import "./product.css";
import { langContext } from "../../context/langContext";

const Product = ({ data }) => {
  const { locale } = useContext(langContext);
  const { urlImg, model, esLang, usLang } = data;
  const lang = locale === "en-US" ? usLang : esLang;
  return (
    <div className="d-content">
      <article className="product__card">
        <div className="card__container-img">
          <img src={urlImg} alt="bateria drumco" className="card__image" />
        </div>
        <h3 className="card__title">{model}</h3>
        <p className="card__description">{lang.smallDescription}</p>
      </article>
    </div>
  );
};

export default Product;
