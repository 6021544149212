import React from "react";
import Contact from "../components/contact/Contact";
import Distributors from "../components/distributors/Distributors";
import Demos from "../components/demos/Demos";
import Events from "../components/events/Events";
import { FeatProducts } from "../components/featProd/FeatProducts";
import Gallery from "../components/gallery/Gallery";
import { Hero } from "../components/hero/Hero";
import Modal from "../components/Modal/Modal";

import { useModal } from "../hooks/useModal";
import { useState } from "react";

const initialModal = {
  urlImage: "",
  alt: "",
  classNameImg: "",
};

const HomePage = () => {
  const { isOpen, openModal, closeModal } = useModal(false);
  const [modalG, setModalG] = useState(initialModal);

  return (
    <div>
      <Hero />
      <FeatProducts />
      <Demos />
      <Gallery openModal={openModal} setModalG={setModalG} />
      <Events />
      <Contact />
      <Distributors />
      <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        classTall={modalG.classNameImg}
      >
        <img
          className={`modal__image ${modalG.classNameImg}`}
          src={modalG.urlImage}
          alt={modalG.alt}
        />
      </Modal>
    </div>
  );
};

export default HomePage;
