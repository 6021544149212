import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { LangProvider } from "./context/langContext";
import { SearchProvider } from "./context/searchContext";
import { DataProvider } from "./context/dataContext";
import ProductsPage from "./pages/ProductsPage";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import HomePage from "./pages/HomePage";

//Importacion de estilos
import "./App.css";
import ProductPage from "./pages/ProductPage";

function App() {
  return (
    <LangProvider>
      <DataProvider>
        <Router>
          <SearchProvider>
            <Header />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/product" element={<ProductPage />} />
              <Route path="/products" element={<ProductsPage />}>
                <Route path="/products/:category" element={<ProductsPage />} />
              </Route>
              <Route path="*" element={<h1>Page not found</h1>} />
            </Routes>
            <Footer />
          </SearchProvider>
        </Router>
      </DataProvider>
    </LangProvider>
  );
}

export default App;
