import React from "react";

//Importacion de estilos
import "./modal.css";

const Modal = ({ children, isOpen, closeModal, classTall }) => {
  return (
    <article className={`modal ${isOpen && "is-open"}`}>
      <div className={`modal__container ${classTall}`}>
        <button className="modal__btnClose" onClick={closeModal}>
          X
        </button>
        {children}
      </div>
    </article>
  );
};

export default Modal;
