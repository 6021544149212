import { GiWorld, GiHamburgerMenu } from "react-icons/gi";
import { useState, useEffect, useContext } from "react";
import { langContext } from "../../context/langContext";
import { searchContext } from "../../context/searchContext";
import { MdKeyboardArrowDown } from "react-icons/md";
import { AiFillInstagram } from "react-icons/ai";
import { FormattedMessage } from "react-intl";
import { BsFacebook } from "react-icons/bs";
import { ImSearch } from "react-icons/im";
import { Link, useNavigate } from "react-router-dom";
import LOGO from "../../assets/img/Logo.png";

//Importacion de estilos
import "./header.css";

const Header = () => {
  const { setLang, changeLang } = useContext(langContext);
  const { setSearchParams, setSearch, search } = useContext(searchContext);

  const navigate = useNavigate();

  const [showMenu, setShowMenu] = useState(false);
  const [showDrop, setShowDrop] = useState(false);
  const [showDrop2, setShowDrop2] = useState(false);
  const [sticky, setSticky] = useState(false);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearchParams({ search: `${search}` });
    setSearch("");
  };

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 5);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <div className="header header__container">
      <div className="header__section--primary">
        <div className="header__socials">
          <a href="https://www.facebook.com/drumcodrum/" target="blank">
            <BsFacebook className="socials__item" />
          </a>
          <a href="https://www.instagram.com/drumcodrum" target="blank">
            <AiFillInstagram className="socials__item socials__item--med" />
          </a>
        </div>
        <div className="header__lang" onClick={() => setLang(changeLang)}>
          <GiWorld />
          <span>
            <FormattedMessage id="head.btn-idiom" defaultMessage="es" />
          </span>
        </div>
      </div>
      <div className={`header__section--secondary ${sticky ? "sticky" : ""}`}>
        <nav className="navbar">
          <div className="navbar__logo">
            <Link to="/">
              <img className="navbar__img" src={LOGO} alt="logo Drumco" />
            </Link>
          </div>
          <ul className={`navbar__list ${showMenu ? "show--menu" : ""}`}>
            <li className="list__item">
              <Link className="item__link" to="/">
                <FormattedMessage id="head.list-link1" defaultMessage="Home" />
              </Link>
            </li>
            <li className="list__item--drop">
              <div
                className="item__label"
                onClick={() => setShowDrop(!showDrop)}
              >
                <span>
                  <FormattedMessage
                    id="head.list-link2"
                    defaultMessage="Drums"
                  />
                </span>
                <MdKeyboardArrowDown />
              </div>
              <ul className={`item__dropdown ${showDrop ? "show--drop" : ""}`}>
                <li className="dropdown__item">
                  <Link className="item__link-drop" to="/products/Junior-Drums">
                    Junior
                  </Link>
                </li>
                <li className="dropdown__item">
                  <Link
                    className="item__link-drop"
                    to="/products/Electric-Drums"
                  >
                    Electric
                  </Link>
                </li>
                <li className="dropdown__item">
                  <Link
                    className="item__link-drop"
                    to="/products/Acoustic-Drums"
                  >
                    Acoustic
                  </Link>
                </li>
              </ul>
            </li>
            <li className="list__item--drop">
              <div
                className="item__label"
                onClick={() => setShowDrop2(!showDrop2)}
              >
                <span>
                  <FormattedMessage
                    id="head.list-link3"
                    defaultMessage="Drumsticks"
                  />
                </span>
                <MdKeyboardArrowDown />
              </div>
              <ul className={`item__dropdown ${showDrop2 ? "show--drop" : ""}`}>
                <li className="dropdown__item">
                  <Link
                    className="item__link-drop"
                    to="/products/Wood-Drumsticks"
                  >
                    Wood
                  </Link>
                </li>
                <li className="dropdown__item">
                  <Link
                    className="item__link-drop"
                    to="/products/Nylon-Drumsticks"
                  >
                    Nylon
                  </Link>
                </li>
              </ul>
            </li>
            <li className="list__item">
              <Link className="item__link" to="/products/Timpanies">
                <FormattedMessage
                  id="head.list-link4"
                  defaultMessage="Timpanies"
                />
              </Link>
            </li>
            <li className="list__item">
              <Link className="item__link" to="/products/Drumhead">
                <FormattedMessage
                  id="head.list-link5"
                  defaultMessage="Drumheads"
                />
              </Link>
            </li>
          </ul>
          <div>
            <form className="form__input" action="">
              <input
                className="search__input"
                type="search"
                placeholder="search"
                value={search}
                onChange={handleChange}
                onFocus={() => navigate("/products")}
              />
              <button className="search__btn" onClick={handleSubmit}>
                <ImSearch />
              </button>
            </form>
          </div>
          <div className="navbar__menu" onClick={() => setShowMenu(!showMenu)}>
            <GiHamburgerMenu />
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
